<template>
  <div>
    <div class="row">
      <div class="col-xxl-12">
        <div class="card">
          <div class="card-body">
            <div class="form row">
              <div class="form-group col-md-3">
                <label class="col-md-12"> Fecha Inicio:*</label>
                <input required type="date" class="form-control" v-model="filtro.data_inicio" />
              </div>
              <div class="form-group col-md-3">
                <label class="col-md-12"> Fecha Final:*</label>
                <input required type="date" class="form-control" v-model="filtro.data_fim" />
              </div>
              <div class="col-md-4">
                <label class="col-md-12"> Seleccione las filiales:*</label>
                <treeselect :multiple="true" :options="options" placeholder="Seleccione una o más(s)..."
                  v-model="value" />
                <!-- <div class="mt-5 ml-1">
                  Selected: <strong>{{ value }}</strong>
                </div> -->
              </div>
              <div class="col-md-1 d-flex pt-6" style="">
                <!-- <div class=""> -->

                <div class="col-2 pt-2">
                  <b-button variant="success" @click="pesquisar()"><i
                      class="fa fa-search search-icon m-0 p-0"></i></b-button>
                </div>
                <!-- <div class="col-2">
                    <b-button variant="outline-warning">
                      <i class="fa fa-print m-0 p-0"></i>
                    </b-button>
                  </div> -->

                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-12 mt-5">
        <div class="card">
          <div class="card-body">
            <div class="row text-center">
              <div class="col-md-6">
                <h4>Atendimientos por Filiales</h4>

                <div class="pt-2 col-12">
                  <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                    :width="width" :height="height" />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <h4>Salidas de insumos por Clases</h4>
                <div class="pt-6">
                  <saidasclasses></saidasclasses>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Bar } from "vue-chartjs/legacy";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import saidasclasses from "@/view/components/relatorios/graficos/saidas_por_classe";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import M from "minimatch";
import filialService from "../../../core/services/filial.service";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar, saidasclasses },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: ["CT - Famrmacia", "C01", "C02"],
        datasets: [{ data: [0, 0, 0] }],
      },
      chartOptions: {
        responsive: true,
      },
      filtro: {
        filiais_id: [],
        data_inicio: null,
        data_fim: null,
      },
      value: [],
      options: [
        {
          id: "PJC",
          label: "PJC",
          children: [
            {
              id: "pear",
              label: "CT - Farmacia",
            },
            {
              id: "strawberry",
              label: "Clinica 3",
            },
          ],
        },
        {
          id: "CDE",
          label: "CDE",
          children: [],
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatorio Geral" }]);
  },
  created() {
    this.listar_atendimentos_filial();
    this.listar_filial();
  },
  computed: {
    num_atendimentos() {
      return this.$store.state.relatorios.num_atendimentos;
    },
    num_saidas() {
      return this.$store.state.relatorios.num_saidas;
    },
    num_saidas_por_filial() {
      return this.$store.state.relatorios.num_saidas_por_filial;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
  },
  methods: {
    async listar_atendimentos_filial() {
      await this.$store.dispatch(
        "relatorios/num_atendimentos_por_filial",
        this.filtro
      );
      await this.$store.dispatch(
        "relatorios/num_saidas_por_filial",
        this.filtro
      );
      await this.$store.dispatch("relatorios/num_saidas_por_tipo");
      // await this.$store.dispatch("relatorios/num_atendimentos_geral");
      var l = [];
      var total = [];
      var saidas = [];

      for (let i = 0; i < this.num_atendimentos.length; i++) {
        const labels = this.num_atendimentos[i].labels;
        l.push(labels);
        total.push(this.num_atendimentos[i].datasets);

        const s = 0;
        for (let j = 0; j < this.num_saidas_por_filial.length; j++) {
          if (this.num_saidas_por_filial[j].labels == labels)
            s = this.num_saidas_por_filial[j].datasets;
        }
        saidas.push(s);
      }

      var novosDados = {
        labels: l,
        datasets: [
          {
            label: "Atendimentos",
            backgroundColor: "rgba(54, 162, 235, 0.3)",
            borderColor: "rgb(54, 162, 235)",
            data: total,
          },
          {
            label: "Salidas de Insumos",
            backgroundColor: "rgba(153, 102, 255, 0.3)",
            borderColor: "rgb(153, 102, 255)",
            data: saidas,
          },
        ],
      };
      this.chartData = novosDados;
    },
    async listar_filial() {
      await this.$store.dispatch("configEmpresa/listar_filial");
      var pjc = [];
      var cde = [];
      var p = {};
      for (let i = 0; i < this.lista_filials.length; i++) {
        const filial = this.lista_filials[i];
        p = {
          id: filial.id,
          label: filial.nome,
        };

        if (filial.empresa_id == 1) {
          pjc[i] = p;
        } else {
          cde[i] = p;
        }
      }
      this.options[0].children = pjc;
      this.options[1].children = cde;
      console.log(this.options);
    },

    async pesquisar() {
      this.filtro.filiais_id = this.value
      console.log(this.filtro)
      await this.$store.dispatch(
        "relatorios/num_atendimentos_por_filial",
        this.filtro
      );
      await this.$store.dispatch(
        "relatorios/num_saidas_por_filial",
        this.filtro
      );
      // this.setDadosGrafico()
    },
    setDadosGrafico() {
      var l = [];
      var total = [];
      var saidas = [];

      for (let i = 0; i < this.num_atendimentos.length; i++) {
        const labels = this.num_atendimentos[i].labels;
        l.push(labels);
        total.push(this.num_atendimentos[i].datasets);

        const s = 0;
        for (let j = 0; j < this.num_saidas_por_filial.length; j++) {
          if (this.num_saidas_por_filial[j].labels == labels)
            s = this.num_saidas_por_filial[j].datasets;
        }
        saidas.push(s);
      }

      var novosDados = {
        labels: l,
        datasets: [
          {
            label: "Atendimientos",
            backgroundColor: "rgba(54, 162, 235, 0.3)",
            borderColor: "rgb(54, 162, 235)",
            data: total,
          },
          {
            label: "Salidas de Insumos",
            backgroundColor: "rgba(153, 102, 255, 0.3)",
            borderColor: "rgb(153, 102, 255)",
            data: saidas,
          },
        ],
      };
      this.chartData = novosDados;
    },
  },
};
</script>

<style></style>